import React from "react";
import Menu from '../components/Menu'
import beppe1 from "./../images/beppe/Beppe1.jpg"
import beppe2 from "./../images/beppe/Beppe2.jpg"
import beppe3 from "./../images/beppe/Beppe3.jpg"
import beppe4 from "./../images/beppe/Beppe4.jpg"
import beppe5 from "./../images/beppe/Beppe5.jpg"
import beppe6 from "./../images/beppe/Beppe6.jpg"
import beppe7 from "./../images/beppe/Beppe7.jpg"


const beppe = [
    beppe1,
    beppe2,
    beppe3,
    beppe4,
    beppe5,
    beppe6,
    beppe7,
  ];

const randomImage = beppe[Math.floor(Math.random() * beppe.length)];

const Bored = () => {
  return (
    <div className="App">
      <Menu/>
      <div class="aligndivcenter"> 
        <div style={{ height:`450` }}>
        <p> Get a pic of my doggo 🐶</p>
        <img style={{ marginTop:`7%` }} id="beppe" src={randomImage} alt="Beppe's random"/>
        </div>
        <div style={{ marginTop:`10%` }}>
        <a class="button bouncy" href="./bored">More</a>
        </div>
      </div>
    </div>
  );
};

export default Bored;
