import React from 'react';

function Main() {
    return(
        <div class="aligndiv">
            <div>
                <a href="#main">
                    <img id="profileph" src="./images/me_illustration.png" alt="my beautiful face"></img>
                </a>
                <h1>Hello, I'm Alberto <img id="rocket" src="./images/rocket.png" alt="rocket"></img></h1>
                <h2>I'm an Italian engineer currently working as IT, Security & Privacy lead @ <a style={{ textDecoration: 'none' }} href="https://www.bendingspoons.com">Bending Spoons</a>.</h2>
            </div>
            <div class="links">
                <a href="https://www.facebook.com/alberto.germano.5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff">
                        <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"></path>
                    </svg>
                </a>
                <a href="https://www.linkedin.com/in/alberto-germano-8300b7ba">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="#fff">
                        <path d="M 8 3.0097656 C 4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188 C 2.0097656 10.819219 4.59 12.990234 8 12.990234 C 11.47 12.990234 13.990234 10.870625 13.990234 7.890625 C 13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15 C 2.45 15 2 15.45 2 16 L 2 45 C 2 45.55 2.45 46 3 46 L 13 46 C 13.55 46 14 45.55 14 45 L 14 16 C 14 15.45 13.55 15 13 15 L 3 15 z M 18 15 C 17.45 15 17 15.45 17 16 L 17 45 C 17 45.55 17.45 46 18 46 L 27 46 C 27.552 46 28 45.552 28 45 L 28 30 L 28 29.75 L 28 29.5 C 28 27.13 29.820625 25.199531 32.140625 25.019531 C 32.260625 24.999531 32.38 25 32.5 25 C 32.62 25 32.739375 24.999531 32.859375 25.019531 C 35.179375 25.199531 37 27.13 37 29.5 L 37 45 C 37 45.552 37.448 46 38 46 L 47 46 C 47.55 46 48 45.55 48 45 L 48 28 C 48 21.53 44.529063 15 36.789062 15 C 33.269062 15 30.61 16.360234 29 17.490234 L 29 16 C 29 15.45 28.55 15 28 15 L 18 15 z"></path>
                    </svg>
                </a>
                <a href="https://www.instagram.com/bebosettete">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" version="1.1">
                        <path fill="#fff" d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z "></path>
                    </svg>
                </a>
                <a href="mailto:alberto.germano1993@gmail.com">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 24 24" fill="#fff">
                        <path d="M 4 4 C 2.897 4 2 4.897 2 6 L 2 18 C 2 19.103 2.897 20 4 20 L 20 20 C 21.103 20 22 19.103 22 18 L 22 6 C 22 4.897 21.103 4 20 4 L 4 4 z M 4 6 L 20 6 L 20 6.0058594 L 12 11 L 4 6.0039062 L 4 6 z M 4 8.0039062 L 12 13 L 20 8.0058594 L 20.001953 18 L 4 18 L 4 8.0039062 z"></path>
                    </svg>
                </a>
            </div>
        </div>
    );
}

export default Main;
