import './App.css';
import AboutMe from "./pages/about-me";
import Bored from "./pages/bored";
import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
       {/*All our Routes goes here!*/}
       <Route exact path="/" component={AboutMe}/>
       <Route exact path="/bored" component={Bored}/>
       {/* <Route exact component={NotFoundPage}/> */}
      </Router>
    );
  }
}

export default App;
