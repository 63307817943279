import React from 'react';
import { Link } from "react-router-dom";

function Menu() {
    return(
        <div class="menu">
            <ul>
                <li class="menu"><Link to="/">About me</Link></li>
                <li class="menu"><Link to="/bored">Bored?</Link></li>
            </ul>
        </div>
    );
}

export default Menu;
