import React from "react";
import Main from '../components/Main'
import Menu from '../components/Menu'

const AboutMe = () => {
  return (
    <div className="App">
      <Menu/>
      <Main/>
    </div>
  );
};

export default AboutMe;
